import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'twin.macro'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1 } from '../../components/Headings'
import Hero from '../../components/Hero'
import Container from '../../components/Container'
import { ButtonA } from '../../components/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

const PageTemplate = ({ data }) => {
  const page = data.allWpPage.edges[0].node
  const {
    title,
    content,
    featuredImage,
    cookMuseum: { iconBoxes },
  } = page

  return (
    <>
      <SEO />
      <Layout>
        <Hero background={featuredImage?.node.localFile.childImageSharp.fixed}>
          <div tw="text-center py-16 md:(py-32)">
            <H1 tw="mb-8 text-white">{title}</H1>
            <ButtonA href="https://www.cookmuseum.org/" large>
              Learn More <FontAwesomeIcon icon={faSignOutAlt} />
            </ButtonA>
          </div>
        </Hero>
        <Container tw="py-12 md:(py-16) text-center prose-xl">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Container>
        <Container tw="md:(grid grid-cols-3 col-gap-32)">
          {iconBoxes.map(({ title, paragraph, icon }) => (
            <div tw="text-center flex flex-col items-center mb-12 md:(mb-0)">
              <div tw="bg-yellow-600 flex items-center justify-center h-40 w-40 rounded-full mb-8">
                <Img fixed={icon.localFile.childImageSharp.fixed} />
              </div>
              <strong tw="text-2xl">{title}</strong>
              <p>{paragraph}</p>
            </div>
          ))}
        </Container>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          content
          ...FeaturedImage
          cookMuseum {
            iconBoxes {
              title
              paragraph
              icon {
                localFile {
                  childImageSharp {
                    fixed(width: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
